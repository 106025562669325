
html {
    font-size: 16px;
}

//media query for screens smaller than 480px 
@media screen and (max-width: 480px) {
    html {
        font-size: 16px;
    }
}

//media query for screens greater than 480px and smaller than 768px
@media screen and (min-width: 480px) and (max-width: 1560px) {
    html {
        font-size: 15px;
    }
}

//media query between width 480px and 1300px and height greater than 600px 

@media screen and (min-width: 480px) and (max-width: 1300px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 480px) and (max-width: 880px) and (min-height: 600px) {
    html {
        font-size: 23px;
    }
}


body {
    margin: 0;
    font-family: 'Museo Sans' 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//create font-face with different weights from different files
@font-face {
    font-family: 'Museo Sans';
    src: url('../fonts/MuseoSans-100.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('../fonts/MuseoSans-300.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('../fonts/MuseoSans_500.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('../fonts/MuseoSans_700.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Museo Sans';
    src: url('../fonts/Museo900-Regular.otf') format('opentype');
    font-weight: 900;
}

.sections-container{
    position: 'relative';
    z-index: 900;
}

.introduction--wrapper {
    z-index: 1000;
    background-color: white;
    position: fixed;
    padding: 0;
    margin: 0;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .introduction--header {
        font-family: 'Museo Sans';
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

